var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section",class:{ 'section-invert': _vm.invertColors }},[_c('v-container',{staticClass:"pa-0 section__background",attrs:{"fluid":""}},[_c('div',{staticClass:"background",class:{
      'force-right': _vm.data.configuration.wb_image_to_right__checkbox__,
      'force-left': !_vm.data.configuration.wb_image_to_right__checkbox__
    }},[_c('v-img',{attrs:{"height":"100%","width":"100%","src":_vm.getImageUrl('img_1')}})],1),_c('v-container',{staticClass:"full-height",attrs:{"fluid":_vm.data.configuration.wb_container_fluid__checkbox__}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"content pt-4 mb-0",class:{
                 'd-flex align-center': _vm.data.configuration.wb_align_center__checkbox__,
               },style:({ minHeight: ((_vm.data.configuration['wb_height__style_height__']) + "px") }),attrs:{"cols":"12","md":"8","offset-md":_vm.data.configuration.wb_image_to_right__checkbox__ ? 0 : 4}},[_c('div',{staticClass:"full-width full-height"},[_c('div',{staticClass:"full-width style-1-base pa-4",class:{
                  'style-2-base': _vm.invertColors
               },domProps:{"innerHTML":_vm._s(_vm.data.configuration.wb_text__html_text__)}}),(_vm.hasCtaButtons)?_c('div',{staticClass:"full-width style-1-base d-flex flex-wrap justify-center mt-n1 pb-4",class:{
                  'style-2-base': _vm.invertColors
               }},_vm._l((_vm.data.configuration.wb_buttons__array_cta_buttons__),function(button,index){return _c('default-button',{key:index,attrs:{"button":button,"additional-class":"ma-2"}},[_vm._v(" "+_vm._s(button.title)+" ")])}),1):_vm._e()])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }